import React from 'react';

const IconLogo = () => (
  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
  //   <title>Logo</title>
  //   <g transform="translate(-8.000000, -2.000000)">
  //     <g transform="translate(11.000000, 5.000000)">
  //       <path
  //         d="M435.651,850.174l126.064-68.158V637.388L437.036,563.135,310.695,632.124V776.474Z"
  //         fill="currentColor"
  //       />
  //       <polygon
  //         id="Shape"
  //         stroke="currentColor"
  //         strokeWidth="5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         points="39 0 0 22 0 67 39 90 78 68 78 23"
  //       />
  //     </g>
  //   </g>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="261.021"
    height="298.536"
    viewBox="0 0 261.021 298.536">
    <g transform="translate(-238.695 -107.379)">
      <text
        transform="translate(293 324)"
        fill="#63ffda"
        font-size="200"
        font-family="Roboto, sans-serif"
        font-weight="600">
        <tspan x="0" y="0">
          S
        </tspan>
      </text>
      <path
        d="M435.651,850.174l126.064-68.158V637.388L437.036,563.135,310.695,632.124V776.474Z"
        transform="translate(-67 -450)"
        fill="none"
        stroke="#63ffda"
        stroke-width="10"
      />
    </g>
  </svg>
);

export default IconLogo;
